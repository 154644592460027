import React from "react";
import classNames from "classnames";
import { v4 } from "uuid";

import { handleError } from "@frontend/api/handle-error";
import { addDownloadNotifyUser, BatchType, removeDownloadNotifyUser } from "@frontend/api/media.service";

import { useAccounts } from "@shared/hooks/use-accounts";
import { useDownloadQueue } from "@shared/hooks/use-download-queue";
import { IconButton } from "@shared/primitives/button";
import { Checkbox } from "@shared/primitives/checkbox";
import { ProgressRing } from "@shared/primitives/progress-ring";
import { downloadQueueStore, QueueFile, QueueFileStatus } from "@shared/state/download-queue";

import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiCheckboxCircleFill,
  RiCloseLine,
  RiDownloadLine,
  RiNotification3Line
} from "@remixicon/react";

type LocalQueueItem = QueueFile & {
  mediaId: string;
  batchId: string;
};

export const DownloadQueue = () => {
  const [show, setShow] = React.useState(false);
  const [expanded, setExpanded] = React.useState(true);
  const [localQueue, setLocalQueue] = React.useState<LocalQueueItem[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [currentBatchId, setCurrentBatchId] = React.useState("");
  const [currentMediaId, setCurrentMediaId] = React.useState("");
  const { entities } = useDownloadQueue();
  const { user } = useAccounts();
  const notifyUserId = entities.find((e) => e.mediaId === currentMediaId)?.notifyUserId || "";
  const hasIncompleteItems = React.useMemo(() => {
    return localQueue.some((file) => file.status !== QueueFileStatus.Complete);
  }, [localQueue]);

  const itemsInProgressCount = localQueue.filter((file) => file.status !== QueueFileStatus.Complete).length;

  React.useEffect(() => {
    const updatedLocalQueue = [...localQueue];

    if (entities.length === 0) {
      return;
    }

    const normalisedQueue: LocalQueueItem[] = entities.flatMap(({ mediaId, batchId, files }) =>
      files.map((file) => ({
        mediaId,
        batchId,
        ...file
      }))
    );

    if (normalisedQueue.length === 0) {
      setLocalQueue(localQueue.map((file) => ({ ...file, status: QueueFileStatus.Complete })));
      return;
    }

    if (normalisedQueue.length !== 0) {
      for (const item of normalisedQueue) {
        const localQueueIndex = updatedLocalQueue.findIndex(({ id }) => id === item.id);

        if (updatedLocalQueue[localQueueIndex]) {
          updatedLocalQueue[localQueueIndex] = item;
        } else {
          const id = v4();
          updatedLocalQueue.push({ id, ...item });
        }
      }
      const { mediaId = "", batchId = "" } = normalisedQueue[normalisedQueue.length - 1];
      setShow(true);
      setCurrentBatchId(batchId);
      setCurrentMediaId(mediaId);
      setLocalQueue(updatedLocalQueue);
    }
  }, [entities]);

  const handleToggleNotification = async () => {
    if (!currentBatchId || !user) {
      return;
    }
    setIsLoading(true);
    try {
      if (notifyUserId) {
        await removeDownloadNotifyUser(currentMediaId, currentBatchId);
        downloadQueueStore.updateDownloadNotifyUser(currentMediaId, "");
        return;
      }
      await addDownloadNotifyUser(currentMediaId, currentBatchId);
      downloadQueueStore.updateDownloadNotifyUser(currentMediaId, user.id);
    } catch (error) {
      handleError(error);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setShow(false);
    setLocalQueue([]);
  };

  const renderFiles = localQueue.map((item) => {
    const isComplete = item.status === QueueFileStatus.Complete;
    const fileType =
      item.batchFile.batchType === BatchType.Audio
        ? ".mp3"
        : item.batchFile.batchType === BatchType.Video
        ? ".mp4"
        : "." + item.batchFile.type;
    const uploadingStatus = !isComplete ? (
      <ProgressRing
        className="tw-mr-[-3px] tw-shrink-0"
        progress={item.progress ?? 0}
        labelled={false}
        radius={10}
        stroke={5}
        theme="primary"
      />
    ) : (
      <></>
    );

    return (
      <li key={item.id} className="tw-flex tw-w-full tw-border-b tw-border-b-neutral-200">
        <div className={classNames("tw-flex tw-w-full tw-items-center tw-gap-2 tw-p-2 tw-pr-[21px]")}>
          {isComplete ? (
            <RiCheckboxCircleFill className="tw-h-5 tw-w-5 tw-shrink-0 tw-text-success-500" />
          ) : (
            <RiDownloadLine className="tw-h-5 tw-w-5 tw-shrink-0" />
          )}
          <span
            className={"tw-flex-1 tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-text-sm tw-font-normal"}
          >
            {item.name}
            {fileType}
          </span>
          {uploadingStatus}
        </div>
      </li>
    );
  });

  return (
    <span
      className={classNames(
        "tw-flex tw-w-[438px] tw-translate-y-0 tw-transform tw-flex-col tw-overflow-hidden tw-rounded-md tw-border-l tw-border-r tw-border-t tw-border-neutral-200 tw-bg-white tw-opacity-100 tw-shadow-lg tw-transition-all",
        { "!tw-hidden tw-translate-y-full": !show }
      )}
    >
      <header className="tw-flex tw-w-full tw-border-b tw-border-b-neutral-200 tw-bg-neutral-100 tw-p-2.5">
        {hasIncompleteItems ? (
          <RiNotification3Line className="tw-h-5 tw-w-5 tw-shrink-0" />
        ) : (
          <RiCheckboxCircleFill className="tw-h-5 tw-w-5 tw-shrink-0 tw-text-success-500" />
        )}
        <span className="tw-ml-1 tw-mr-auto tw-text-sm tw-text-neutral-900">
          {hasIncompleteItems ? "Email me when download is ready" : "Download complete"}
        </span>
        {hasIncompleteItems && (
          <Checkbox
            large
            checked={Boolean(notifyUserId)}
            loading={isLoading}
            disabled={isLoading}
            onChange={handleToggleNotification}
            className="tw-mr-2 tw-gap-0"
          />
        )}
        <IconButton
          variant="ghost"
          size="wrap"
          icon={
            expanded ? (
              <RiArrowDownSLine className="tw-h-5 tw-w-5 tw-shrink-0" />
            ) : (
              <RiArrowUpSLine className="tw-h-5 tw-w-5 tw-shrink-0" />
            )
          }
          className="tw-mt-0.5 tw-h-5 tw-w-5 tw-rounded-full"
          onClick={() => setExpanded(!expanded)}
        />
        <IconButton
          variant="ghost"
          size="wrap"
          icon={<RiCloseLine className="tw-h-5 tw-w-5 tw-shrink-0" />}
          className={classNames("tw-ml-3 tw-mt-0.5 tw-h-5 tw-w-5 tw-rounded-full", {
            "tw-hidden": hasIncompleteItems
          })}
          onClick={handleClose}
        />
      </header>
      {expanded && <ul className="tw-flex tw-max-h-[300px] tw-flex-col tw-overflow-y-auto">{renderFiles}</ul>}
      {hasIncompleteItems && (
        <footer className="tw-flex tw-w-full tw-border-b tw-border-b-neutral-200 tw-bg-neutral-100 tw-p-2.5">
          <div className="tw-text-sm">
            Downloading {itemsInProgressCount} of {localQueue.length} files...
          </div>
        </footer>
      )}
    </span>
  );
};
