import React from "react";

import { deleteAsset, uploadAsset } from "@frontend/api/assets.service";
import { EN } from "@frontend/assets/i18n/en";

import { fontNamesFor } from "@shared/assets/fonts";
import { LockFeature } from "@shared/components/lock-feature/lock-feature";
import { useAssets } from "@shared/hooks/use-assets";
import { usePlanPermissions } from "@shared/hooks/use-plan-permissions";
import { AssetType } from "@shared/interfaces/asset";
import { SublyPlan } from "@shared/interfaces/billing";
import { Button } from "@shared/primitives/button";
import { FileButton } from "@shared/primitives/file-input";
import { notificationError } from "@shared/primitives/notification";
import { ISelectGroup, ISelectOption, Select } from "@shared/primitives/select";
import { ToolTip } from "@shared/primitives/tooltip";
import { useActiveMediaIdState, useCurrentMediaLanguage } from "@shared/state/editor/editor.hooks";

import { AssStyle } from "@getsubly/common";
import { useMediaEditor } from "@media-editor/contexts/media-editor.context";
import { RiAlertFill } from "@remixicon/react";

type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>;

interface FontExtra {
  id?: string;
  url?: string;
  fontName?: string;
}

interface FontDropdownProps {
  value: string;
  className?: string;
  onChange: (value: AtLeast<AssStyle, "font">) => void;
}

export const FontDropdown: React.FC<FontDropdownProps> = ({ value, onChange, className }) => {
  const { hasPermission } = usePlanPermissions(SublyPlan.Premium);
  const { fontAssets } = useAssets();
  const mediaId = useActiveMediaIdState();
  const currentLanguage = useCurrentMediaLanguage();

  const [isUploading, setUploading] = React.useState(false);
  const [isEditing, setEditing] = React.useState(false);

  const handleChangeFont = (font: string | undefined, extra?: FontExtra) => {
    if (!font) return;

    if (extra?.id && extra?.url && extra?.fontName) {
      return onChange({ font, customFont: { id: extra.id, url: extra.url, fontName: extra.fontName } });
    }

    onChange({ font });
  };

  const handleUpload = async (file: File) => {
    setUploading(true);

    const asset = await uploadAsset({ file, assetType: AssetType.FONT });

    handleChangeFont(asset.name, { id: asset.id, url: asset.publicUrl ?? "", fontName: asset.name });

    setUploading(false);
  };

  const handleDeleteCustomFont = async (assetId: string) => {
    try {
      const selectedFont = fontAssets.find((font) => font.id === assetId)?.name;
      if (selectedFont === value) {
        onChange({ font: "Verdana", customFont: undefined });
      }

      await deleteAsset({ assetId });

      if (fontAssets.length <= 1) {
        setEditing(false);
      }
    } catch (error) {
      notificationError(EN.error.defaultMessage);
    }
  };

  const options: ISelectGroup[] = React.useMemo(() => {
    let myFontOptions: ISelectOption[] = [];

    if (hasPermission) {
      myFontOptions = fontAssets.map((font) => {
        return {
          label: font.metadata?.label ?? font.name,
          value: font.name,
          disabled: font.metadata.s3_file_is_missing,
          extra: {
            id: font.id,
            url: font.publicUrl ?? "",
            fontName: font.name,
            s3_file_is_missing: font.metadata.s3_file_is_missing
          }
        };
      });
    }

    const foreignFontOptions = fontNamesFor(currentLanguage?.languageCode).map((font) => ({
      label: font,
      value: font
    }));
    const latinFontOptions = fontNamesFor().map((font) => ({ label: font, value: font }));

    return [
      { label: "My fonts", id: "my-fonts", options: myFontOptions, deletable: isEditing },
      { label: `${currentLanguage?.language} fonts`, id: "foreign-fonts", options: foreignFontOptions },
      { label: null, id: "latin-fonts", options: latinFontOptions }
    ];
  }, [fontAssets, hasPermission, currentLanguage, isEditing]);

  return (
    <FontDropdownInput
      value={value}
      className={className}
      acceptUploadTypes={[".ttf"]}
      optionGroups={options}
      isUploading={isUploading}
      isEditing={isEditing}
      onUpload={handleUpload}
      onChangeFont={handleChangeFont}
      onDeleteCustomFont={handleDeleteCustomFont}
      onSetEditing={setEditing}
    />
  );
};

type FontDropdownInputProps = {
  value: string;
  className?: string;
  acceptUploadTypes: string[];
  optionGroups: ISelectGroup[];
  isUploading: boolean;
  isEditing: boolean;
  onUpload: (file: File) => void;
  onChangeFont: (font: string | undefined, extra?: FontExtra) => void;
  onDeleteCustomFont: (id: string) => void;
  onSetEditing: (isEditing: boolean) => void;
};

const FontDropdownInput: React.FC<FontDropdownInputProps> = ({
  value,
  className,
  acceptUploadTypes = [],
  optionGroups,
  isUploading,
  isEditing,
  onUpload,
  onChangeFont,
  onDeleteCustomFont,
  onSetEditing
}) => {
  const { settings } = useMediaEditor();

  return (
    <Select
      value={value}
      className={className}
      options={optionGroups}
      formatGroupHeader={(group) => {
        if (group.id === "my-fonts") {
          return (
            <div className="tw-flex tw-min-h-[44px] tw-items-center tw-justify-between tw-px-6 tw-py-3 tw-text-sm tw-text-neutral-900">
              <p className="tw-text-sm tw-font-medium">My Fonts</p>
              <Button
                variant="ghost"
                className="tw-p-0 tw-text-sm tw-font-medium tw-text-primary-500 hover:tw-text-primary-500"
                onClick={() => onSetEditing(!isEditing)}
                size="36"
              >
                {isEditing ? "Done" : "Edit"}
              </Button>
            </div>
          );
        } else if (group.id === "latin-fonts") {
          return <p className="tw-mt-2 tw-px-6 tw-py-3 tw-text-sm tw-font-medium">{group.label}</p>;
        }

        return;
      }}
      formatOptionLabel={(option) => (
        <>
          <span style={{ fontFamily: option.value }}>{option.label}</span>
          {option.extra?.s3_file_is_missing && (
            <div className="tw-pointer-events-auto tw-ml-auto" onClick={(event) => event?.stopPropagation()}>
              <ToolTip text="Font not found. Please upload again." place="bottom">
                <RiAlertFill className="tw-h-4 tw-w-4 tw-text-warning-500" />
              </ToolTip>
            </div>
          )}
        </>
      )}
      onDeleteOption={({ extra }) => extra?.id && onDeleteCustomFont(extra.id)}
      formatInputSelectedLabel={(option) => <span style={{ fontFamily: option.value }}>{option.label}</span>}
      dropdownFooter={() => {
        return (
          !settings.features.isIframe && (
            <LockFeature minPermission={SublyPlan.Premium}>
              <FileButton
                accept={acceptUploadTypes.join(",")}
                onChange={onUpload}
                loading={isUploading}
                variant="ghost"
                className="tw-w-full tw-px-6 tw-py-3 tw-text-sm tw-font-medium !tw-text-primary-500 hover:!tw-bg-neutral-50 hover:!tw-text-primary-500"
              >
                {isUploading ? "Uploading..." : "+ Upload custom font"}
              </FileButton>
            </LockFeature>
          )
        );
      }}
      onChange={onChangeFont}
    />
  );
};
