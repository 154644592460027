import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";

import { LoggedInRoute } from "@frontend/components/auth/logged-in-route";
import { LoggedOutRoute } from "@frontend/components/auth/logged-out-route";
import { SettingsLayout } from "@frontend/layouts/settings.layout";
import { Page404 } from "@frontend/pages/404";
import { OAuth2GoogleCallbackPage } from "@frontend/pages/auth/google/callback";
import { OAuth2MicrosoftCallbackPage } from "@frontend/pages/auth/microsoft/callback";
import { ZoomProcessPage } from "@frontend/pages/callback/zoom-process";
import { ForgotPasswordPage } from "@frontend/pages/forgot-password";
import { LoginPage } from "@frontend/pages/login";
import { VerifyMagicLink } from "@frontend/pages/magic-link";
import { PreviewVideoPage } from "@frontend/pages/preview/[public-share-id]";
import { ResetPasswordPage } from "@frontend/pages/reset/[id]/[code]";
import { APIPage } from "@frontend/pages/settings/api";
import { AppsPage } from "@frontend/pages/settings/apps";
import { AppPage } from "@frontend/pages/settings/apps/[app-id]";
import { BillingPage } from "@frontend/pages/settings/billing";
import { AddBillingDetailsPage } from "@frontend/pages/settings/billing/edit";
import { InvoicesPage } from "@frontend/pages/settings/billing/invoices";
import { GlossaryPage } from "@frontend/pages/settings/glossary";
import { GroupsPage } from "@frontend/pages/settings/groups";
import { ProfilePage } from "@frontend/pages/settings/profile";
import { AccountEditPage } from "@frontend/pages/settings/profile/edit";
import { UsersPage } from "@frontend/pages/settings/users";
import { WorkspacePage } from "@frontend/pages/settings/workspace";
import { SignUpPage } from "@frontend/pages/signup";
import { VerifyUserPage } from "@frontend/pages/verify/[id]/[code]";
import { VerifyEmailPage } from "@frontend/pages/verify-email/[id]/[code]";

import { ErrorBoundary } from "./components/error-boundary";
import { DashboardLayout } from "./layouts/dashboard.layout";
import { OneDriveLayout } from "./layouts/onedrive.layout";
import { CreateOrJoinPage } from "./pages/create-or-join";
import { FolderPage } from "./pages/folders/[id]";
import { OneDriveCaptionPage } from "./pages/onedrive/caption";
import { OneDriveErrorPage } from "./pages/onedrive/error";
import { OneDriveLoginPage } from "./pages/onedrive/login";
import { OneDriveProcessPage } from "./pages/onedrive/process";
import { OneDriveSuccessPage } from "./pages/onedrive/success";
import { OneDriveSummarisePage } from "./pages/onedrive/summarise";
import { OneDriveTranscriptionPage } from "./pages/onedrive/transcription";
import { OpenWorkspacePage } from "./pages/open-workspace/[workspaceId]";
import { OrdersPage } from "./pages/orders";
import { UsageOverviewPage } from "./pages/settings/usage";
import { UsageTransactionsPage } from "./pages/settings/usage/transactions";
import { SublyClickPage } from "./pages/subly-click";
import { SublyClickInstallPage } from "./pages/subly-click-install";
import { DashboardPage } from "./pages";
import {
  CREATE_OR_JOIN_PATH,
  DASHBOARD_ALL_MEDIA_PATH,
  DASHBOARD_PATH,
  DASHBOARD_SHARED_WITH_ME_PATH,
  EMAIL_VERIFICATION_PATH,
  EXPIRED_APPS_PATH,
  EXTERNAL_VERIFICATION_PATH,
  FOLDER_PATH,
  FOLDERS_PATH,
  FORGOT_PASSWORD_PATH,
  LOGIN_PATH,
  MAGIC_LINK_PATH,
  MEDIA_EDITOR_PATH,
  MEDIA_PATH,
  OAUTH2_GOOGLE_CALLBACK_PATH,
  OAUTH2_MICROSOFT_CALLBACK_PATH,
  OLD_MEDIA_EDITOR_PATH,
  OPEN_WORKSPACE_PATH,
  PREVIEW_VIDEO_PATH,
  RESET_PASSWORD_PATH,
  SETTINGS_API_PATH,
  SETTINGS_APP_PATH,
  SETTINGS_APPS_PATH,
  SETTINGS_BILLING_DETAILS_PATH,
  SETTINGS_BILLING_INVOICES_PATH,
  SETTINGS_BILLING_PATH,
  SETTINGS_GLOSSARY_PATH,
  SETTINGS_GROUPS_PATH,
  SETTINGS_PROFILE_EDIT_PATH,
  SETTINGS_PROFILE_PATH,
  SETTINGS_USAGE_PATH,
  SETTINGS_USAGE_TRANSACTIONS_PATH,
  SETTINGS_USERS_PATH,
  SETTINGS_WORKSPACE_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  SUBLY_CLICK_INSTALL_PATH,
  SUBLY_CLICK_PATH,
  VERIFICATION_PATH,
  ZOOM_CALLBACK_PATH
} from "./routes";

const MediaEditor = lazy(() => import("@frontend/pages/media/[media-id]"));

export const AppRoutes: React.FC = () => {
  return (
    <Suspense fallback="">
      <ErrorBoundary>
        <Routes>
          {/* PUBLIC ROUTES - accessible to all users regardless of their logged in status */}
          {/* prettier-ignore */}
          <Route path={OAUTH2_GOOGLE_CALLBACK_PATH} element={<OAuth2GoogleCallbackPage callbackPath={OAUTH2_GOOGLE_CALLBACK_PATH}  />} />
          {/* prettier-ignore */}
          <Route path={OAUTH2_MICROSOFT_CALLBACK_PATH} element={<OAuth2MicrosoftCallbackPage callbackPath={OAUTH2_MICROSOFT_CALLBACK_PATH} />} />

          <Route path={OLD_MEDIA_EDITOR_PATH} element={<OldVideosRedirect />} />
          <Route path={FORGOT_PASSWORD_PATH} element={<ForgotPasswordPage />} />
          <Route path={RESET_PASSWORD_PATH} element={<ResetPasswordPage />} />
          <Route path={VERIFICATION_PATH} element={<VerifyUserPage />} />
          <Route path={EXTERNAL_VERIFICATION_PATH} element={<VerifyUserPage external />} />
          <Route path={PREVIEW_VIDEO_PATH} element={<PreviewVideoPage />} />
          <Route path={SUBLY_CLICK_PATH} element={<SublyClickPage />} />
          <Route path={SUBLY_CLICK_INSTALL_PATH} element={<SublyClickInstallPage />} />
          <Route path={SIGN_IN_PATH} element={<Navigate to={DASHBOARD_PATH} />} />
          <Route path={EXPIRED_APPS_PATH} element={<Navigate to={SETTINGS_APPS_PATH} />} />

          {/* LOGGED OUT ROUTES - only accessible to logged out users  */}
          {/* prettier-ignore */}
          <Route path={LOGIN_PATH} element={<LoggedOutRoute><LoginPage /></LoggedOutRoute>} />
          {/* prettier-ignore */}
          <Route path={SIGN_UP_PATH} element={<LoggedOutRoute><SignUpPage /></LoggedOutRoute>} />
          {/* prettier-ignore */}
          <Route path={MAGIC_LINK_PATH} element={<LoggedOutRoute><VerifyMagicLink /></LoggedOutRoute>} />

          {/* LOGGED IN ROUTES - only accessible to logged in users */}
          <Route path="/" element={<LoggedInRoute />}>
            <Route path={MEDIA_EDITOR_PATH} element={<MediaEditor />} />
            <Route path={EMAIL_VERIFICATION_PATH} element={<VerifyEmailPage />} />
            <Route path={ZOOM_CALLBACK_PATH} element={<ZoomProcessPage />} />

            <Route path="onedrive" element={<OneDriveLayout />}>
              <Route path="login" element={<OneDriveLoginPage />} />
              <Route path="caption" element={<OneDriveCaptionPage />} />
              <Route path="transcribe" element={<OneDriveTranscriptionPage />} />
              <Route path="summarise" element={<OneDriveSummarisePage />} />
              <Route path="process" element={<OneDriveProcessPage />} />
              <Route path="success" element={<OneDriveSuccessPage />} />
              <Route path="error" element={<OneDriveErrorPage />} />
            </Route>

            <Route path={OPEN_WORKSPACE_PATH} element={<OpenWorkspacePage />} />
            <Route path={CREATE_OR_JOIN_PATH} element={<CreateOrJoinPage />} />

            {/* Treat the rest as logged in user's dashboard. */}
            <Route path={DASHBOARD_PATH} element={<DashboardLayout />}>
              <Route path="orders" element={<OrdersPage />} />
              <Route path={DASHBOARD_PATH} element={<DashboardPage />} />
              <Route path={DASHBOARD_ALL_MEDIA_PATH} element={<FolderPage />} />
              <Route path={DASHBOARD_SHARED_WITH_ME_PATH} element={<FolderPage />} />
              <Route path={FOLDERS_PATH} element={<Navigate to={DASHBOARD_PATH} />} />
              <Route path={FOLDER_PATH} element={<FolderPage />} />
              <Route path="settings" element={<SettingsLayout />}>
                <Route path={SETTINGS_PROFILE_PATH} element={<ProfilePage />} />
                <Route path={SETTINGS_WORKSPACE_PATH} element={<WorkspacePage />} />
                <Route path={SETTINGS_PROFILE_EDIT_PATH} element={<AccountEditPage />} />
                <Route path={SETTINGS_BILLING_DETAILS_PATH} element={<AddBillingDetailsPage />} />
                <Route path={SETTINGS_API_PATH} element={<APIPage />} />
                <Route path={SETTINGS_BILLING_INVOICES_PATH} element={<InvoicesPage />} />
                <Route path={SETTINGS_BILLING_PATH} element={<BillingPage />} />
                <Route path={SETTINGS_USERS_PATH} element={<UsersPage />} />
                <Route path={SETTINGS_GROUPS_PATH} element={<GroupsPage />} />
                <Route path={SETTINGS_APP_PATH} element={<AppPage />} />
                <Route path={SETTINGS_APPS_PATH} element={<AppsPage />} />
                <Route path={SETTINGS_GLOSSARY_PATH} element={<GlossaryPage />} />
                <Route path={SETTINGS_USAGE_PATH} element={<UsageOverviewPage />} />
                <Route path={SETTINGS_USAGE_TRANSACTIONS_PATH} element={<UsageTransactionsPage />} />
              </Route>
            </Route>
          </Route>

          <Route path="*" element={<Page404 />} />
        </Routes>
      </ErrorBoundary>
    </Suspense>
  );
};

// Force the old /videos/:mediaId route to redirect to the new /media/:mediaId route
const OldVideosRedirect: React.FC = () => {
  const { mediaId } = useParams();

  return <Navigate to={`${MEDIA_PATH}/${mediaId}`} />;
};
